import * as THREE from 'three'
import Experience from '@experience/Experience.js'
import Debug from '@experience/Utils/Debug.js'
import Properties from "@experience/Properties.js";
import Resources from "@experience/Utils/Resources.js";
import * as helpers from "@experience/Utils/HelpersFunctions.js";
import Model from "./Abstracts/Model.js";

export default class CirculatorySystem extends Model {
    experience = new Experience()
    debug = new Debug()
    properties = new Properties()
    scene = experience.scene
    time = experience.time
    camera = experience.camera.instance
    renderer = experience.renderer.instance
    resources = experience.resources
    cursor = experience.cursor
    timeline = experience.timeline;

    loadingModel = false;

    sources = [ {
        name: 'circulatorySystemModel',
        type: 'gltfModel',
        path: 'models/circulatory_low/circulatory_low.gltf'
    } ]
    sourcesReady = 'CirculatorySystemReady'

    container = new THREE.Group();

    constructor( body ) {
        super()
        this.body = body

        // this.setModel()
        // this.setDebug()
    }

    setModel() {
        this.circulatorySystemModel = this.resources.items.circulatorySystemModel.scene

        helpers.meshToBatchedMesh( this.circulatorySystemModel, this.container, this.body.batchedMeshesIds )

        //this.body.changeVisibility( this.circulatorySystemModel, false )

        //this.container.add( this.circulatorySystemModel );
        //helpers.cloneAllMaterials( this.container );
        this.body.container.add( this.container );
    }

    resize() {

    }

    setDebug() {
        if ( !this.debug.active ) return
    }

    update( deltaTime ) {

    }

}
