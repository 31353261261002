import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Environment {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug

        this.scene.colorSpace = THREE.SRGBColorSpace
        //this.scene.background = new THREE.Color( '#ffffff' )

        this.setAmbientLight()
        this.setDirectionalLight()
        //this.setEnvironmentMap()

        this.setDebug()
    }

    setAmbientLight() {
        this.ambientLight = new THREE.AmbientLight( '#ffffff', 1.0 )
        this.scene.add( this.ambientLight )
    }

    setDirectionalLight() {
        this.directionalLight = new THREE.DirectionalLight( '#ffffff', 2.4 )
        this.directionalLight.position.set( 0, 5, -5 )
        this.scene.add( this.directionalLight )
    }


    setEnvironmentMap() {
        this.environmentMap = this.resources.items.evnMapTexture
        this.environmentMap.mapping = THREE.EquirectangularReflectionMapping
        this.environmentMap.colorSpace = THREE.SRGBColorSpace
        this.scene.environment = this.environmentMap
    }

    setDebug() {
        if ( this.debug.active ) {
            if ( this.debug.panel ) {
                this.debugFolder = this.debug.panel.addFolder( {
                    title: 'Lights',
                    expanded: true
                } )

                const debugFolder = this.debugFolder
                    .addFolder( {
                        title: 'Directional light'
                    } )

                debugFolder
                    .addBinding(
                        this.directionalLight,
                        'intensity',
                        { min: 0, max: 10, step: 0.01 }
                    )

                const debugFolderAmbient = this.debugFolder
                    .addFolder( {
                        title: 'Ambient light'
                    } )

                debugFolderAmbient
                    .addBinding(
                        this.ambientLight,
                        'intensity',
                        { min: 0, max: 10, step: 0.01 }
                    )

            }
        }
    }
}
