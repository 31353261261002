import * as THREE from 'three'
import Experience from '@experience/Experience.js'
import Debug from '@experience/Utils/Debug.js'
import Properties from "@experience/Properties.js";
import Resources from "@experience/Utils/Resources.js";
import * as helpers from "@experience/Utils/HelpersFunctions.js";
import Model from "./Abstracts/Model.js";

export default class MuscleSystem extends Model {
    experience = new Experience()
    debug = new Debug()
    properties = new Properties()
    scene = experience.scene
    time = experience.time
    camera = experience.camera.instance
    renderer = experience.renderer.instance
    resources = experience.resources
    cursor = experience.cursor
    timeline = experience.timeline;

    loadingModel = false;

    sources = [ {
        name: 'digestiveSystemModel',
        type: 'gltfModel',
        path: 'models/digestive_system/digestive.gltf'
    } ]
    sourcesReady = 'DigestiveSystemReady'

    container = new THREE.Group();

    constructor( body ) {
        super()
        this.body = body

        //this.setModel()
        //this.setAnimationDigestiveSystem()
        //this.setDebug()
    }

    setModel() {
        this.digestiveSystemSource = this.resources.items.digestiveSystemModel
        this.digestiveSystemModel = this.digestiveSystemSource.scene

        helpers.meshToBatchedMesh( this.digestiveSystemModel, this.container, this.body.batchedMeshesIds )

        //this.body.changeVisibility( this.digestiveSystemModel, false )

        //this.container.add( this.digestiveSystemModel )
        //helpers.cloneAllMaterials( this.container )
        this.body.container.add( this.container )
    }


    setAnimationDigestiveSystem() {
        this.animation = {}

        // Mixer
        this.animation.mixer = new THREE.AnimationMixer( this.digestiveSystemModel )

        // Actions
        this.animation.actions = {}

        this.animation.actions.idle = this.animation.mixer.clipAction( this.digestiveSystemSource.animations[ 0 ] )

        this.animation.actions.current = this.animation.actions.idle
        this.animation.actions.current.play()

        // Play the action
        this.animation.play = ( name ) => {
            const newAction = this.animation.actions[ name ]
            const oldAction = this.animation.actions.current

            newAction.reset()
            newAction.play()
            newAction.crossFadeFrom( oldAction, 1 )

            this.animation.actions.current = newAction
        }
    }

    resize() {

    }

    setDebug() {
        if ( !this.debug.active ) return
    }

    update( deltaTime ) {
        this.animation && this.animation.mixer.update( this.time.delta )
    }

}
