import * as THREE from 'three'
import Experience from '../Experience.js'
import Debug from '../Utils/Debug.js'
import Properties from "../Properties.js";
import Ui from '../Ui/Ui.js'

export default class Input {
    experience = new Experience()
    debug = new Debug()
    properties = new Properties()
    ui = new Ui()
    scene = experience.scene
    time = experience.time
    camera = experience.camera.instance
    renderer = experience.renderer.instance
    resources = experience.resources
    cursor = experience.cursor
    timeline = experience.timeline;
    container = new THREE.Group();
    raycaster = new THREE.Raycaster();
    clickPosition = new THREE.Vector2( 0, 0 );
    selectedMesh = null;
    previousSelectedMesh = null;

    timeoutOffset = 130;

    constructor() {
        this.init()
        this.setDebug()
    }

    init() {
        this.addEvents()
    }

    addEvents() {
        const canvas = this.experience.canvas
        let isDragging = false;
        let timeoutOnDrag = null;
        let mouseDown = false;

        canvas.addEventListener( 'mousedown', () => {
            clearTimeout( timeoutOnDrag );
            timeoutOnDrag = null;
            isDragging = false;
            mouseDown = true;
        } );

        canvas.addEventListener( 'mousemove', () => {
            if ( !timeoutOnDrag && mouseDown) {
                mouseDown = false;

                timeoutOnDrag = setTimeout( () => {
                    isDragging = true;
                }, this.timeoutOffset );
            }
        } );

        canvas.addEventListener( 'click', ( event ) => {
            mouseDown = false;

            if ( isDragging ) {
                isDragging = false;
                clearTimeout( timeoutOnDrag );
                timeoutOnDrag = null;
                return;
            }

            this.placeObjectClickHandler( event );
        } );

        canvas.addEventListener( 'touchstart', this.placeObjectTouchHandler, {
            once: false
        } ); // Add touch listener.

        // prevent scroll/pinch gestures on canvas
        canvas.addEventListener( 'touchmove', ( event ) => {
            event.preventDefault();
        } );
    }

    placeObjectTouchHandler = ( e ) => {
        if ( e.touches.length === 2 ) {

        }

        if ( e.touches.length > 2 ) {
            return;
        }

        // calculate tap position in normalized device coordinates (-1 to +1) for both components.
        this.clickPosition.x = ( e.touches[ 0 ].clientX / window.innerWidth ) * 2 - 1;
        this.clickPosition.y = -( e.touches[ 0 ].clientY / window.innerHeight ) * 2 + 1;
    };

    placeObjectClickHandler = ( e ) => {
        // calculate tap position in normalized device coordinates (-1 to +1) for both components.
        this.clickPosition.x = ( e.clientX / window.innerWidth ) * 2 - 1;
        this.clickPosition.y = -( e.clientY / window.innerHeight ) * 2 + 1;

        this.shiftKey = e.shiftKey;
        this.ctrlKey = e.ctrlKey;

        this.raycastToBody()
    };

    getNDCtoScreen(x, y) {
        const vector = new THREE.Vector3(x, y, 0.5);
        vector.unproject(this.camera);

        return vector;

        // const dir = vector.sub(this.camera.position).normalize(); // Направление от камеры к точке в NDC
        // const cameraDirection = new THREE.Vector3();
        // this.camera.getWorldDirection(cameraDirection); // Направление взгляда камеры
        //
        // // Расстояние до плоскости, перпендикулярной направлению взгляда камеры
        // const distance = - this.camera.position.dot(cameraDirection) / dir.dot(cameraDirection);
        //
        // // Расчет позиции в мировом пространстве
        // return this.camera.position.clone().add(dir.multiplyScalar(distance));
    }

    getNDC(x, y, z) {
        const vector = new THREE.Vector3( x, y, z );
        vector.project( this.camera );
        return vector;
    }

    raycastToBody() {
        this.experience.world.body.clickIntersect( this.raycaster )
    }

    resize() {

    }

    setDebug() {

    }

    update( deltaTime ) {

    }

}
