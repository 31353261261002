import * as THREE from 'three'
import Experience from '../../Experience.js'
import Debug from '../../Utils/Debug.js'
import Properties from "../../Properties.js";
import Resources from "@experience/Utils/Resources.js";
import * as helpers from "@experience/Utils/HelpersFunctions.js";
import Model from "./Abstracts/Model.js";

export default class ReproductiveSystem extends Model {
    experience = new Experience()
    debug = new Debug()
    properties = new Properties()
    scene = experience.scene
    time = experience.time
    camera = experience.camera.instance
    renderer = experience.renderer.instance
    resources = experience.resources
    cursor = experience.cursor
    timeline = experience.timeline

    loadingModel = false

    sources = [ {
        name: 'reproductiveSystemModel',
        type: 'gltfModel',
        path: 'models/reproductive_system/reproductive_system.gltf'
    } ]
    sourcesReady = 'ReproductiveSystemReady'

    container = new THREE.Group()

    constructor( body ) {
        super()
        this.body = body

        // this.setModel()
        // this.setDebug()
    }

    setModel() {
        this.reproductiveSystemModel = this.resources.items.reproductiveSystemModel.scene

        helpers.meshToBatchedMesh( this.reproductiveSystemModel, this.container, this.body.batchedMeshesIds )

        //this.body.changeVisibility( this.reproductiveSystemModel, false )

        //this.container.add( this.reproductiveSystemModel );
        //helpers.cloneAllMaterials( this.container );
        this.body.container.add( this.container );
    }

    resize() {

    }

    setDebug() {
        if ( !this.debug.active ) return
    }

    update( deltaTime ) {

    }

}
