export default [
    // {
    //     name: 'backgroundSound',
    //     type: 'audio',
    //     path: 'sounds/background.mp3'
    // },
    // {
    //     name: 'bodyModel',
    //     type: 'gltfModel',
    //     path: 'models/body/EB_skeleton_high.gltf'
    // },
    {
        name: 'bonesModel',
        type: 'gltfModel',
        path: 'models/skel3/skeleton_low03.gltf'
    },
    // {
    //     name: 'nervousSystemModel',
    //     type: 'gltfModel',
    //     path: 'models/nervous_system_low2/nervous_system_low2.gltf'
    // },
    // {
    //     name: 'digestiveSystemModel',
    //     type: 'gltfModel',
    //     path: 'models/digestive_system/digestive.gltf'
    // },
    // {
    //     name: 'muscleSystemModel',
    //     type: 'gltfModel',
    //     path: 'models/muscle_system/muscle_system.gltf'
    // },
    // {
    //     name: 'circulatorySystemModel',
    //     type: 'gltfModel',
    //     path: 'models/circulatory_low/circulatory_low.gltf'
    // },
    // {
    //     name: 'connectiveSystemModel',
    //     type: 'gltfModel',
    //     path: 'models/connective_system/connective_system.gltf'
    // },
    // {
    //     name: 'exocrineEndocrineSystemModel',
    //     type: 'gltfModel',
    //     path: 'models/exocrine_endocrine/exocrine_endocrine.gltf'
    // },
    // {
    //     name: 'fatSystemModel',
    //     type: 'gltfModel',
    //     path: 'models/fat/fatty_tissue.gltf'
    // },
    // {
    //     name: 'lymphaticSystemModel',
    //     type: 'gltfModel',
    //     path: 'models/lymphatic_system/lymphatic_system.gltf'
    // },
    // {
    //     name: 'reproductiveSystemModel',
    //     type: 'gltfModel',
    //     path: 'models/reproductive_system/reproductive_system.gltf'
    // },
    // {
    //     name: 'respiratorySystemModel',
    //     type: 'gltfModel',
    //     path: 'models/respiratory_system/respiratory_system.gltf'
    // },
    // {
    //     name: 'urinarySystemModel',
    //     type: 'gltfModel',
    //     path: 'models/urinary_system/urinary_system.gltf'
    // },
    // {
    //     name: 'displacementTexture',
    //     type: 'texture',
    //     path: 'textures/displacement.jpg'
    // },

    // Environment map
    // {
    //     name: 'evnMapTexture',
    //     type: 'texture',
    //     path: 'textures/environmentMaps/white.jpg'
    // }
]
