import * as THREE from 'three'
import Experience from '@experience/Experience.js'
import Debug from '@experience/Utils/Debug.js'
import Properties from "@experience/Properties.js";
import * as helpers from "@experience/Utils/HelpersFunctions.js";

export default class BonesSystem {
    experience = new Experience()
    debug = new Debug()
    properties = new Properties()
    scene = experience.scene
    time = experience.time
    camera = experience.camera.instance
    renderer = experience.renderer.instance
    resources = experience.resources
    cursor = experience.cursor
    timeline = experience.timeline;

    loadingModel = false;

    container = new THREE.Group();

    constructor( body ) {
        this.body = body

        this.setModel()
        this.setDebug()
    }

    setModel() {
        this.bonesModel = this.resources.items.bonesModel.scene

        helpers.meshToBatchedMesh( this.bonesModel, this.container, this.body.batchedMeshesIds )

        //console.log(this.body.batchedMeshesIds)

        //console.log(Object.keys( this.body.batchedMeshesIds )[0])
        //console.log( this.scene.getObjectByProperty('uuid', Object.keys( this.body.batchedMeshesIds )[0].toString()) )
        // console.log( this.scene.getObjectByProperty('uuid', this.scene.children[0].uuid ) )
        // console.log( this.scene.children )


        // this.container.add( this.bonesModel );
        this.body.container.add( this.container );
    }

    resize() {

    }

    setDebug() {
        if ( !this.debug.active ) return
    }

    update( deltaTime ) {

    }

}
