import * as THREE from 'three'
import Experience from './Experience.js'
import * as DetectUA from "./Utils/DetectUA.js"
import MinSignal from "min-signal"
import Sizes from "./Utils/Sizes.js"

const detectUA = DetectUA.default
const userAgent = ( navigator.userAgent || navigator.vendor ).toLowerCase()
const browserName = detectUA.browser.name;

export default class Properties {


    constructor() {
        // Singleton
        if ( Properties._instance ) {
            return Properties._instance
        }
        Properties._instance = this

        this.experience = new Experience()
        this.renderer = this.experience.renderer.instance
        this.scene = this.experience.scene
        this.camera = this.experience.camera.instance
        this.canvas = this.experience.canvas
        this.sizes = new Sizes()

    }


}
