import EventEmitter from './EventEmitter.js'

export default class Time extends EventEmitter {
    constructor() {
        super()

        // Setup
        this.start = Date.now()
        this.current = this.start
        this.playing = true
        this.elapsed = 0
        this.delta = 0.016666666666666668
        this.nextTime = 0;
        this.delay = 1000 / 60 * 1;

        window.requestAnimationFrame( () => {
            this.tick()
        } )
    }

    tick( time ) {
        const currentTime = Date.now()
        this.delta = Math.min( ( currentTime - this.current ) * 0.001, 0.016 )
        this.current = currentTime
        this.elapsed = ( this.current - this.start ) * 0.001

        if ( this.delta > 0.06 ) {
            this.delta = 0.06
        }

        // if ( time < this.nextTime ) {
        //
        //     window.requestAnimationFrame( ( time ) => {
        //         this.tick( time )
        //     } )
        //     return
        // }

        this.nextTime = time + this.delay;

        this.trigger( 'tick' )

        window.requestAnimationFrame( ( time ) => {
            this.tick( time )
        } )
    }
}
