import EventEmitter from '../Utils/EventEmitter.js'
import Translations from '../DB/Translations.json';
import Experience from '../Experience.js'
import Sizes from '../Utils/Sizes.js'


export default class Ui extends EventEmitter {
    experience = new Experience()
    sizes = new Sizes()
    uiElement = document.querySelector( '.ui' )
    uiToggleButton = document.querySelector( '.ui #ui-toggle' )
    meshNameElement = document.querySelector( '.ui .mesh-name' )
    tipsElement = document.querySelector( '.ui .mesh-tips' )
    layersElement = document.querySelector( '.ui .layers' )
    layersForm = document.querySelector( '.ui .layers form' )
    resetButton = document.querySelector( '.ui #reset-scene' )
    hideMode = document.querySelector( '.ui #hide-mode' )
    modelLoadPreloader = document.querySelector( '.model-load-preloader' )
    clickPosition = this.experience.cursor

    constructor() {

        // Singleton
        if ( Ui._instance ) {
            return Ui._instance
        }

        super()

        Ui._instance = this

        this.setFormEvents()
    }

    setFormEvents() {
        const layers = Array.from( this.layersForm.querySelectorAll( 'input' ) )

        // Toggle UI Button
        this.uiToggleButton.addEventListener( 'click', () => {
            this.layersElement.classList.toggle( 'hidden' )
        } )

        // get checked layers layersForm
        this.layersForm.addEventListener( 'change', ( e ) => {
            const layer = e.target

            //get parent element
            layer.parentElement

            switch ( layer.id ) {
                case 'nervous-system':
                    const nervousSystem = this.experience.world.body.nervousSystem
                    nervousSystem.loadModel(layer)
                    this.experience.world.body.changeVisibility(
                        nervousSystem.container,
                        layer.checked
                    )
                    break
                case 'digestive-system':
                    const digestiveSystem = this.experience.world.body.digestiveSystem
                    digestiveSystem.loadModel(layer)
                    this.experience.world.body.changeVisibility(
                        digestiveSystem.container,
                        layer.checked
                    )
                    break
                case 'muscle-system':
                    const muscleSystem = this.experience.world.body.muscleSystem
                    muscleSystem.loadModel(layer)
                    this.experience.world.body.changeVisibility(
                        muscleSystem.container,
                        layer.checked
                    )
                    break
                case 'circulatory-system':
                    const circulatorySystem = this.experience.world.body.circulatorySystem
                    circulatorySystem.loadModel(layer)
                    this.experience.world.body.changeVisibility(
                        circulatorySystem.container,
                        layer.checked
                    )
                    break
                case 'connective-system':
                    const connectiveSystem = this.experience.world.body.connectiveSystem
                    connectiveSystem.loadModel(layer)
                    this.experience.world.body.changeVisibility(
                        connectiveSystem.container,
                        layer.checked
                    )
                    break
                case 'exocrine-endocrine-system':
                    const exocrineEndocrineSystem = this.experience.world.body.exocrineEndocrineSystem
                    exocrineEndocrineSystem.loadModel(layer)
                    this.experience.world.body.changeVisibility(
                        exocrineEndocrineSystem.container,
                        layer.checked
                    )
                    break
                case 'fat-system':
                    const fatSystem = this.experience.world.body.fatSystem
                    fatSystem.loadModel(layer)
                    this.experience.world.body.changeVisibility(
                        fatSystem.container,
                        layer.checked
                    )
                    break
                case 'lymphatic-system':
                    const lymphaticSystem = this.experience.world.body.lymphaticSystem
                    lymphaticSystem.loadModel(layer)
                    this.experience.world.body.changeVisibility(
                        lymphaticSystem.container,
                        layer.checked
                    )
                    break
                case 'reproductive-system':
                    const reproductiveSystem = this.experience.world.body.reproductiveSystem
                    reproductiveSystem.loadModel(layer)
                    this.experience.world.body.changeVisibility(
                        reproductiveSystem.container,
                        layer.checked
                    )
                    break
                case 'respiratory-system':
                    const respiratorySystem = this.experience.world.body.respiratorySystem
                    respiratorySystem.loadModel(layer)
                    this.experience.world.body.changeVisibility(
                        respiratorySystem.container,
                        layer.checked
                    )
                    break
                case 'urinary-system':
                    const urinarySystem = this.experience.world.body.urinarySystem
                    urinarySystem.loadModel(layer)
                    this.experience.world.body.changeVisibility(
                        urinarySystem.container,
                        layer.checked
                    )
                    break
            }
        } )

        // Reset Scene Button
        this.resetButton.addEventListener( 'click', () => {
            this.trigger( 'reset-scene' )
        })
    }

    setMeshName( name ) {
        if( ! Translations[ name ] ) return name;

        this.meshNameElement.innerHTML = Translations[ name ][ 'English Name' ]
    }

    createTip( mesh ) {
        // create span element
        const tip = document.createElement( 'span' )
        tip.id = 'tip_' + mesh.name

        if ( Translations[ mesh.name ] ) {
            tip.innerHTML = Translations[ mesh.name ][ 'English Name' ]
        } else {
            tip.innerHTML = mesh.name
        }

        tip.draggable = true

        let shiftX = 0
        let shiftY = 0

        tip.onmousedown = ( e ) => {
            shiftX = mesh._line._NDC_endPosition.x - this.clickPosition.x;
            shiftY = mesh._line._NDC_endPosition.y - this.clickPosition.y;

            let moveAt = ( e ) => {
                // update line position
                mesh._line._NDC_endPosition.x = this.clickPosition.x + shiftX
                mesh._line._NDC_endPosition.y = this.clickPosition.y + shiftY
            }

            moveAt( e );

            document.addEventListener('mousemove', moveAt )

            document.onmouseup = () => {
                document.removeEventListener('mousemove', moveAt );
                tip.onmouseup = null;
            }

            tip.ondragstart = () => {
                return false;
            };
        }

        tip.addEventListener('touchstart', ( e ) => {
            this.clickPosition.x = e.touches[ 0 ].clientX / this.sizes.width * 2 - 1
            this.clickPosition.y = -( e.touches[ 0 ].clientY / this.sizes.height ) * 2 + 1
            this.clickPosition.side = e.touches[ 0 ].clientX > this.sizes.width / 2 ? 'right' : 'left'

            shiftX = mesh._line._NDC_endPosition.x - this.clickPosition.x;
            shiftY = mesh._line._NDC_endPosition.y - this.clickPosition.y;

            let moveAt = ( e ) => {
                // update line position
                mesh._line._NDC_endPosition.x = this.clickPosition.x + shiftX
                mesh._line._NDC_endPosition.y = this.clickPosition.y + shiftY
            }

            moveAt( e );

            document.addEventListener('touchmove', moveAt )

            document.addEventListener('touchend', () => {
                document.removeEventListener('touchmove', moveAt );
                tip.removeEventListener('touchend', null);
            })

            tip.ondragstart = () => {
                return false;
            };
        }, false);


        this.tipsElement.appendChild( tip )

        return tip
    }

    modelLoaderShow() {
        this.modelLoadPreloader.classList.remove( 'hidden' )
    }

    modelLoaderHide() {
        this.modelLoadPreloader.classList.add( 'hidden' )
    }
}
