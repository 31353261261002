import * as THREE from 'three'
import Experience from './Experience.js'

export default class Renderer {
    constructor() {
        this.experience = new Experience()
        this.canvas = this.experience.canvas
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.camera = this.experience.camera
        this.debug = this.experience.debug
        this.resources = this.experience.resources
        this.timeline = this.experience.timeline
        this.html = this.experience.html

        this.setInstance()
        this.setDebug()
    }

    setInstance() {
        this.clearColor = '#ffffff'

        this.instance = new THREE.WebGLRenderer( {
            canvas: this.canvas,
            powerPreference: "high-performance",
            precision: "lowp",
            antialias: true,
            alpha: false,
            premultipliedAlpha: false,
            stencil: false,
            // depth: false,
            useLegacyLights: false,
            physicallyCorrectLights: false,
        } )

        this.instance.outputColorSpace = THREE.SRGBColorSpace
        // Tone mapping
        // this.instance.toneMapping = THREE.ReinhardToneMapping
        // this.instance.toneMappingExposure = 3


        this.instance.setSize( this.sizes.width, this.sizes.height )
        this.instance.setPixelRatio( Math.min( this.sizes.pixelRatio, 2 ) )

        this.instance.setClearColor( this.clearColor, 1 )
        this.instance.setSize( this.sizes.width, this.sizes.height )
    }

    setDebug() {
        if ( this.debug.active ) {
            if ( this.debug.panel ) {
                this.debugFolder = this.debug.panel.addFolder( {
                    title: 'Renderer',
                    expanded: true
                } )

                // ToneMapping Select
                this.debugFolder.addBlade({
                    view: 'list',
                    label: 'Tone Mapping',
                    options: [
                        {text: 'No', value: THREE.NoToneMapping},
                        {text: 'Linear', value: THREE.LinearToneMapping},
                        {text: 'Reinhard', value: THREE.ReinhardToneMapping},
                        {text: 'Cineon', value: THREE.CineonToneMapping},
                        {text: 'ACESFilmic', value: THREE.ACESFilmicToneMapping},
                        {text: 'AgXToneMapping', value: THREE.AgXToneMapping},
                        {text: 'NeutralToneMapping', value: THREE.NeutralToneMapping},
                    ],
                    value: this.instance.toneMapping,
                }).on('change', (ev) => {
                    this.instance.toneMapping = ev.value
                });

                // ToneMappingExposure Number
                this.debugFolder
                    .addBinding(
                        this.instance,
                        'toneMappingExposure',
                        { min: 0, max: 10, step: 0.01 }
                    )

            }

        }
    }

    update() {
        if ( this.debug.active ) {
            this.debugRender()
        } else {
            this.productionRender()
        }
    }

    productionRender() {
        this.scene.background = null
        this.instance.setClearColor( 0x000000, 0)
        this.instance.render( this.scene, this.camera.instance )
    }

    debugRender() {
        this.instance.autoClear = false
        this.instance.clearColor( this.clearColor )
        this.instance.render( this.scene, this.camera.instance )
        this.instance.clearDepth()
    }

    resize() {
        // Instance
        this.instance.setSize( this.sizes.width, this.sizes.height )
        this.instance.setPixelRatio( this.sizes.pixelRatio )
    }

    destroy() {

    }
}
