import * as THREE from 'three'
import * as helpers from '../../Utils/HelpersFunctions.js'
import Experience from '../../Experience.js'
import Debug from '../../Utils/Debug.js'
import Properties from "../../Properties.js";
import Ui from '../../Ui/Ui.js'
import { Vector3 } from "three";

// Body Meshes
import NervousSystem from "./NervousSystem.js";
import DigestiveSystem from "./DigestiveSystem.js";
import MuscleSystem from "./MuscleSystem.js";
import BonesSystem from "./BonesSystem.js";
import CirculatorySystem from "./CirculatorySystem.js";
import ConnectiveSystem from "./ConnectiveSystem.js";
import ExocrineEndocrineSystem from "./ExocrineEndocrineSystem.js";
import FatSystem from "./FatSystem.js";
import LymphaticSystem from "./LymphaticSystem.js";
import ReproductiveSystem from "./ReproductiveSystem.js";
import RespiratorySystem from "./RespiratorySystem.js";
import UrinarySystem from "./UrinarySystem.js";

// import CustomShaderMaterial from 'three-custom-shader-material/vanilla'
//
// import MeshSelectedVertex from '../Shaders/MeshSelected/vertex.glsl'
// import MeshSelectedFragment from '../Shaders/MeshSelected/fragment.glsl'

export default class Body {
    experience = new Experience()
    debug = new Debug()
    properties = new Properties()
    ui = new Ui()
    sizes = this.experience.sizes
    input = experience.world.input
    scene = experience.scene
    time = experience.time
    camera = experience.camera.instance
    cameraClass = experience.camera
    renderer = experience.renderer.instance
    resources = experience.resources
    cursor = experience.cursor
    timeline = experience.timeline;
    container = new THREE.Group();
    selectedContainer = new THREE.Group();
    batchedMeshesIds = {}

    hiddenMeshes = []
    tips = {
        lines: new THREE.Group()
    }

    constructor() {
        this.setModel()
        this.addEvents()
        this.setCamera()
        this.setDebug()
    }

    setModel() {

        this.bonesSystem = new BonesSystem( this )
        this.nervousSystem = new NervousSystem( this )
        this.digestiveSystem = new DigestiveSystem( this )
        this.muscleSystem = new MuscleSystem( this )
        this.circulatorySystem = new CirculatorySystem( this )
        this.connectiveSystem = new ConnectiveSystem( this )
        this.exocrineEndocrineSystem = new ExocrineEndocrineSystem( this )
        this.fatSystem = new FatSystem( this )
        this.lymphaticSystem = new LymphaticSystem( this )
        this.reproductiveSystem = new ReproductiveSystem( this )
        this.respiratorySystem = new RespiratorySystem( this )
        this.urinarySystem = new UrinarySystem( this )

        helpers.cloneAllMaterials( this.container )

        // this.boxHelper = new THREE.BoxHelper( this.container, 0xffff00 );
        // this.scene.add( this.boxHelper );

        this.scene.add( this.container );
        this.container.add( this.selectedContainer );
        this.scene.add( this.tips.lines );

    }

    addEvents() {
        this.ui.on('reset-scene', () => {
            this.setUnselectedAllBatchMeshes()
        })
    }

    setLineMesh( points = [], mesh ) {
        //create a blue LineBasicMaterial
        const material = new THREE.LineBasicMaterial( {
            color: 0x000000,
            depthTest: false,
        } );
        const geometry = new THREE.BufferGeometry().setFromPoints( points );

        const lineMesh = new THREE.Line( geometry, material );
        lineMesh.frustumCulled = false;

        lineMesh._NDC_endPosition = this.input.getNDC( ...points[ 1 ] )
        lineMesh._meshId = mesh.id
        lineMesh._mesh = mesh
        mesh._line = lineMesh

        this.tips.lines.add( lineMesh );
    }

    drawLineToScreen( mesh ) {
        // fix matrix for bugs meshes (correct position)
        const positions = mesh.geometry.clone().applyMatrix4( mesh.matrixWorld ).attributes.position.array

        //let startPosition = helpers.getMinPositionY( positions )
        let startPosition = helpers.getCentroid( positions )
        let endPosition = new THREE.Vector2().copy( this.input.getNDC( ...startPosition ) )
        endPosition.y -= 0.1

        if ( this.cursor.side === 'right' ) {
            endPosition.x += 0.3
        } else {
            endPosition.x -= 0.3
        }

        endPosition.clampScalar( -0.8, 0.8 )

        endPosition = this.input.getNDCtoScreen( endPosition.x, endPosition.y )

        this.setLineMesh( [ startPosition, endPosition ], mesh );
    }

    setCamera() {
        // get model bounding box center
        //const box = new THREE.Box3().setFromObject( this.container );
        const box = new THREE.Box3().setFromObject( this.bonesSystem.container );
        const centerBox = box.getCenter( new THREE.Vector3() );

        this.camera.position.setY( centerBox.y );
        this.cameraClass.controls.target = centerBox;
    }

    setSelectedMesh( mesh ) {

        if ( this.input.shiftKey && !this.ui.hideMode.checked ) {
            this.selectMeshToggle( mesh )
            return
        }

        if ( this.ui.hideMode.checked || this.input.ctrlKey ) {
            this.hideMesh( mesh )
            return
        }

        const batchedMeshesVisible = true

        this.setUnselectedAllBatchMeshes()
        mesh.batchedMesh.setVisibleAt( mesh.batchId, false)
        this.selectMeshToggle( mesh )
    }

    setUnelectedMultipleMesh( mesh ) {
        this.selectOriginalMeshToggle( mesh )
    }

    hideMesh( mesh ) {
        mesh.visible = false
        this.hiddenMeshes.push( mesh )
    }

    selectMeshToggle( mesh ){
        if ( mesh._selected ) {
            this.setUnselectedMesh( mesh )
            mesh._tip.remove()

            // remove line
            this.removeLine( mesh.id )
        } else {

            mesh.material.emissive = new THREE.Color( 0x00feff );
            mesh.material.emissiveIntensity = 1;
            mesh.material.needsUpdate = true;
            mesh._selected = true;
            mesh._tip = this.ui.createTip( mesh )

            this.drawLineToScreen( mesh )
        }

    }

    selectOriginalMeshToggle( mesh ){
        if ( mesh._selected ) {
            this.setUnselectedMesh( mesh )
            mesh._tip.remove()

            // remove line
            this.removeLine( mesh.id )

            mesh.batchedMesh.setVisibleAt( mesh.batchId, true)
            this.selectedContainer.remove( mesh )
        } else {

            mesh.material.emissive = new THREE.Color( 0x00feff );
            mesh.material.emissiveIntensity = 1;
            mesh.material.needsUpdate = true;
            mesh._selected = true;
            mesh._tip = this.ui.createTip( mesh )

            this.drawLineToScreen( mesh )
        }

    }

    setUnselectedMesh( mesh ) {
        if ( !mesh ) return

        mesh.material.emissive = new THREE.Color( 0x000000 );
        mesh.material.emissiveIntensity = 0;
        mesh.material.needsUpdate = true;
        mesh._selected = false;
        mesh._tip?.remove()
    }

    setUnselectedAllMeshes() {
        this.container.traverse( child => {
            if ( child.isMesh ) {
                this.hiddenMeshes.forEach( hiddenMesh => {
                    hiddenMesh.visible = true
                })

                this.hiddenMeshes = []

                this.setUnselectedMesh( child )
            }
        })

        this.removeLines()
    }

    setUnselectedAllBatchMeshes( batchedMeshesVisible = false ) {

        // Remove all selected meshes (generated)
        if ( this.selectedContainer.children.length ) {
            for ( let i = this.selectedContainer.children.length - 1; i >= 0; i-- ) {
                const child = this.selectedContainer.children[ i ]
                if ( child.isMesh ) {
                    child.visible = true
                    this.selectedContainer.remove( child )
                    this.setUnselectedMesh( child )
                }
            }
        }

        // Show all batched meshes
        if ( !batchedMeshesVisible ) {
            Object.keys( this.batchedMeshesIds ).forEach( ( batch ) => {
                const batchedMesh = this.batchedMeshesIds[ batch ] [ 'batchedMesh' ]
                const _geometryCount = batchedMesh._geometryCount
                for ( let i = 0; i < _geometryCount; i++ ) {
                    batchedMesh.setVisibleAt( i, true )
                }
            } )
        }

        this.removeLines()
    }

    removeLine( meshId ) {
        for ( let i = this.tips.lines.children.length - 1; i >= 0; i-- ) {
            if ( this.tips.lines.children[ i ]._meshId === meshId ) {
                this.tips.lines.remove( this.tips.lines.children[ i ] );
            }
        }
    }

    removeLines(){
        for(let i = this.tips.lines.children.length - 1; i >= 0; i--){
            this.tips.lines.remove(this.tips.lines.children[i]);
        }
    }

    updateLineMeshes() {
        if ( this.tips.lines.children.length > 0 ) {
            this.tips.lines.children.forEach( line => {
                line.geometry.setFromPoints( [
                    new Vector3( line.geometry.attributes.position.array[ 0 ], line.geometry.attributes.position.array[ 1 ], line.geometry.attributes.position.array[ 2 ] ),
                    this.input.getNDCtoScreen( ...line._NDC_endPosition )
                ] );

                const translateX = line._NDC_endPosition.x * this.sizes.width * 0.5
                const translateY = - line._NDC_endPosition.y * this.sizes.height * 0.5

                if ( line._NDC_endPosition.x < 0) {
                    line._mesh._tip.style.transform = `translateX(calc(${translateX}px - 100%)) translateY(calc(${translateY}px - 50%))`
                } else {
                    line._mesh._tip.style.transform = `translateX(calc(${translateX}px)) translateY(calc(${translateY}px - 50%))`
                }

            } );
        }
    }

    clickIntersect( raycaster ){
        const clickPosition = this.input.clickPosition
        // Update the picking ray with the camera and tap position.
        raycaster.setFromCamera( clickPosition, this.camera );


        // Raycast against the "surface" object.
        const intersects = raycaster.intersectObject( this.container );

        if ( intersects.length > 0 ) {
            let selected = false

            for ( let i = 0; i < intersects.length; i++ ) {
                if ( intersects[ i ].object.visible && intersects[ i ].object.isBatchedMesh ) {
                    selected = true

                    intersects[ i ].object.setVisibleAt(intersects[ i ].batchId, false) // hide batched mesh fix z-fighting

                    const originalMesh = this.batchedMeshesIds[ intersects[ i ].object.uuid ][ intersects[ i ].batchId ];
                    originalMesh.material = new THREE.MeshStandardMaterial( { color: 0x00feff } );

                    originalMesh.batchId = intersects[ i ].batchId
                    originalMesh.batchedMesh = intersects[ i ].object

                    this.selectedMesh = originalMesh;
                    this.ui.setMeshName( originalMesh.name ) // last selected mesh name
                    this.setSelectedMesh( originalMesh )

                    this.selectedContainer.add( originalMesh );

                    break
                }

                if ( intersects[ i ].object.visible && intersects[ i ].object.isMesh ) {

                    selected = true

                    this.setUnelectedMultipleMesh( intersects[ i ].object )
                    //
                    // this.selectedMesh = intersects[ i ].object;
                    this.ui.setMeshName( intersects[ i ].object.name ) // last selected mesh name
                    // this.setSelectedMesh( intersects[ i ].object )

                    break
                }
            }

            if ( !selected ) {
                this.ui.setMeshName( '' )
                this.setUnselectedAllBatchMeshes()
            }

        } else {
            this.ui.setMeshName( '' )
            this.setUnselectedAllBatchMeshes()
        }

        // if ( intersects.length > 0 ) {
        //     let selected = false
        //     for ( let i = 0; i < intersects.length; i++ ) {
        //         if ( intersects[ i ].object.visible ) {
        //
        //             this.selectedMesh = intersects[i].object;
        //             this.ui.setMeshName( intersects[i].object.name ) // last selected mesh name
        //             this.setSelectedMesh( intersects[i].object )
        //             selected = true
        //
        //             break
        //         }
        //     }
        //
        //     if ( !selected ) {
        //         this.ui.setMeshName( '' )
        //         this.setUnselectedAllMeshes()
        //     }
        // } else {
        //     this.ui.setMeshName( '' )
        //     this.setUnselectedAllMeshes()
        // }
    }

    changeVisibility( mesh, visible ) {
        mesh.traverse( child => {
            if ( child.isMesh ) {
                child.visible = visible
            }
        })
    }

    resize() {

    }

    setDebug() {
        if ( !this.debug.active ) return
    }

    update( deltaTime ) {
        this.digestiveSystem?.update( deltaTime )
        this.updateLineMeshes()
    }

}
