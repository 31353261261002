import * as THREE from 'three'
import Experience from '@experience/Experience.js'
import Debug from '@experience/Utils/Debug.js'
import Properties from "@experience/Properties.js";
import Resources from "@experience/Utils/Resources.js";
import * as helpers from "@experience/Utils/HelpersFunctions.js";
import Model from "./Abstracts/Model.js";

export default class MuscleSystem extends Model {
    experience = new Experience()
    debug = new Debug()
    properties = new Properties()
    scene = experience.scene
    time = experience.time
    camera = experience.camera.instance
    renderer = experience.renderer.instance
    resources = experience.resources
    cursor = experience.cursor
    timeline = experience.timeline;

    loadingModel = false;

    container = new THREE.Group();

    sources = [ {
        name: 'muscleSystemModel',
        type: 'gltfModel',
        path: 'models/muscle_system/muscle_system.gltf'
    } ]
    sourcesReady = 'MuscleSystemReady'

    constructor( body ) {
        super()
        this.body = body

        // this.setModel()
        // this.setDebug()
    }

    setModel() {
        this.muscleSystemModel = this.resources.items.muscleSystemModel.scene

        helpers.meshToBatchedMesh( this.muscleSystemModel, this.container, this.body.batchedMeshesIds )
        //this.body.changeVisibility( this.muscleSystemModel, false )

        //this.container.add( this.muscleSystemModel );
        //helpers.cloneAllMaterials( this.container );
        this.body.container.add( this.container );
    }

    resize() {

    }

    setDebug() {
        if ( !this.debug.active ) return
    }

    update( deltaTime ) {

    }

}
